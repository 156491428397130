<template>
  <div class="refund">
    <refund-card title="基础信息:" >
      <el-form-item label="学生姓名：">
        {{ reDisplay.student_name }}
      </el-form-item>
      <el-form-item label="学生身份证号：">
        {{ reDisplay.student_idcard }}
      </el-form-item>
      <el-form-item label="报名学校：">
        {{ reDisplay.school_name }}
      </el-form-item>
      <el-form-item label="学生类型：">
        {{ reDisplay.student_type }}
      </el-form-item>
      <el-form-item label="入学年级：">
        {{ reDisplay.grade_name }}
      </el-form-item>
      <el-form-item label="订单号：">
        {{ reDisplay.order_number }}
      </el-form-item>
      <el-form-item label="订单状态：">
        {{ reDisplay.order_status_cn }}
      </el-form-item>
      <el-form-item label="实缴金额：">
        {{ reDisplay.order_pay_money }}
      </el-form-item>
    </refund-card>
    <refund-edit ref="form" v-model="form" />
    <div class="footer-button">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card";
import Upload from "@/components/common/upload";
import RefundEdit from "../components/RefundEdit";
import RefundCard from "../components/RefundCard";
import {getDetails} from "./edit";
export default {
	_config:{"route":{"path":"refund","meta":{"title":"发起退费"}}},
  data(){
    let isModify = this.$route.query.is_modify;
    let id = this.$route.query.id;
    return {
      isModify,
      id,
      // 表单数据
      form:{

      },
      reDisplay:{}
    }
  },
  mounted(){
    getDetails(this.id).then(res => {
      let data = res.data.data;
      let {
        money,
        refund_day,
        refund_status,
        refund_type,
        bank_card_number,
        opening_bank,
        account_holder,
        refund_transactors_id,
        refund_transactors_name,
        describe,
        refund_img,
      } = data;
      this.reDisplay = data;
      if(this.isModify)
        this.form = {
          money,
          refund_day,
          refund_status,
          refund_type,
          bank_card_number,
          opening_bank,
          account_holder,
          refund_transactors_id,
          refund_transactors_name,
          describe,
          refund_img
        }

    })
  },
  methods:{
    submit(){
      this.$refs.form.validate((val)=>{
        if(val){
          let hint = !this.isModify?"是否确定发起退费?":"是否确认更改退费详情?";
          let okText = !this.isModify?"发起退费":"确认";
          this.$confirm(hint,{
            cancelButtonText:"点错了",
            confirmButtonText:okText,
          }).then(_=>{
            this.form.f_info_id = this.id;
            this.$_register.post("api/refund/offline-PC/offline-refund-apply",this.form).then(res=>{
              if(!this.$route.query.is_modify)
                this.$router.back();
              else
                this.$router.replace("./refund-details?id="+this.id)
            })
          })
        }
      })

    }
  },
  components:{
    TCard:Card,
    TUpload:Upload,
    RefundEdit,
    RefundCard
  }
}
</script>
<style lang="scss" scoped>
.refund{
  background: transparent !important;
  .card{
    background: white;
    padding:10rem;
    margin-bottom: 20rem;
    .content{
      padding: 20rem 30rem;
      .uploader{

      }
    }
  }
  .footer-button{
    background: white;
    border: none;
    line-height: 80rem;
  }
}
</style>
